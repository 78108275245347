export class Assessment {
  key: string;
  value: number;
  description: string;
  color: string;

  constructor(key: string, value: number, description: string, color: string) {
    this.key = key;
    this.value = value;
    this.description = description;
    this.color = color;
  }
}
