import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { AiService } from "../../services/ai.service";
import { Observable } from "rxjs";
import { Airport } from "../../common/airport";
import { AircraftConfigurator } from "../../common/aircraftConfigurator";
import { FormattedPredictionML } from "../../common/formattedPredictionML";
import { NotificationType } from "../../enum/notification-type.enum";
import { NotificationService } from "../../services/notification.service";

@Component({
  selector: "app-ai",
  templateUrl: "./ai.component.html",
  styleUrls: ["./ai.component.css"],
})
export class AiComponent implements OnInit {
  @Input() airport: Airport;
  @Input() aircraftConfigurator: AircraftConfigurator;
  @Output() closeWindow: EventEmitter<boolean> = new EventEmitter<boolean>();

  predictions$: Observable<FormattedPredictionML[]>;
  predictions: FormattedPredictionML[];

  constructor(
    private aiService: AiService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit() {
    this.predictions$ = this.getPredictions();

    this.predictions$.subscribe(
      (data: FormattedPredictionML[]) => {
        this.predictions = data;
        // console.log("Received predictions:", data);
      },
      (error) => console.error("Error fetching predictions", error),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.aircraftConfigurator &&
        changes.aircraftConfigurator.previousValue &&
        changes.aircraftConfigurator.currentValue.idConfigurator !=
          changes.aircraftConfigurator.previousValue.idConfigurator) ||
      (changes.airport &&
        changes.airport.previousValue &&
        changes.airport.currentValue.iata != changes.airport.previousValue.iata)
    ) {
      this.predictions$ = this.getPredictions();
    }
  }

  onCloseWindowEvent($event) {
    this.closeWindow.emit($event);
  }

  getPredictions(): Observable<FormattedPredictionML[]> {
    return this.aiService.getPredictions(
      this.airport.iata,
      this.aircraftConfigurator.idConfigurator,
    );
  }

  createTableHeaderRunwayID(runwayId) {
    return "Runway" + " " + runwayId + ": ";
  }

  copyToClipboard() {
    let textFinal =
      "Assessment of Airport Suitability for Aircraft: " +
      this.aircraftConfigurator.aircraftName +
      "\n\n";

    for (let i = 0; i < this.predictions.length; i++) {
      textFinal +=
        "Runway " +
        this.predictions[i].runwayId +
        ". Final Assessment: " +
        this.predictions[i].finalAssessment["description"] +
        "\n";

      for (let k = 0; k < this.predictions[i].assessments.length; k++) {
        textFinal +=
          this.predictions[i].assessments[k]["key"] +
          ": " +
          this.predictions[i].assessments[k]["description"];

        if (k !== this.predictions[i].assessments.length - 1) {
          textFinal += "\n";
        }
      }

      if (i !== this.predictions.length - 1) {
        textFinal += "\n\n";
      }
    }

    navigator.clipboard
      .writeText(textFinal)
      .then(() => {
        this.notificationService.notify(
          NotificationType.SUCCESS,
          "Text successfully copied to clipboard",
        );
      })
      .catch((error) => {
        this.notificationService.notify(
          NotificationType.ERROR,
          `Error copying text: ${error}`,
        );
      });
  }
}
