import { ComplexAssessment } from "./complexAssessment";

export class ParamAssessment {
  baseEndAssessment: ComplexAssessment;
  reciprocalEndAssessment: ComplexAssessment;
  finalAssessment: ComplexAssessment;

  constructor(
    baseEndAssessment: ComplexAssessment = null,
    reciprocalEndAssessment: ComplexAssessment = null,
    finalAssessment: ComplexAssessment = null,
  ) {
    this.baseEndAssessment = baseEndAssessment;
    this.reciprocalEndAssessment = reciprocalEndAssessment;
    this.finalAssessment = finalAssessment;
  }
}
