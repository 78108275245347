import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "custom-window",
  templateUrl: "./window.component.html",
  styleUrls: ["./window.component.css"],
})
export class WindowComponent implements OnInit {
  @Output() closeWindow: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  onClickCloseWindow() {
    this.closeWindow.emit(false);
  }
}
