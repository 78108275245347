import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Routes, RouterModule} from '@angular/router';
import { SearchComponent } from './components/search/search.component';
import { AirportListComponent } from './components/airport-list/airport-list.component';
import { AirportDetailsComponent } from './components/airport-details/airport-details.component';
import { MainComponent } from './components/main/main.component';
import { AgmCoreModule } from '@agm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserService } from './services/user.service';
import { AuthenticationService } from './services/authentication.service';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { AuthenticationGuard } from './guard/authentication.guard';
import { NotificationModule } from './notification.module';
import { NotificationService } from './services/notification.service';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { UserComponent } from './components/user/user.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClickElseWhereDirective } from './directive/click-elsewhere.directive';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { FeetsToMetersPipe } from 'src/app/pipes/feets-to-meters.pipe';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { FooterComponent } from './components/footer/footer.component';
import { CoordinatesNotNullPipe } from 'src/app/pipes/coordinates-not-null.pipe';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MapComponent } from './components/map/map.component';
import { MarkerService } from './services/marker.service';
import { AboutComponent } from './components/about/about.component';
import { TermsComponent } from './components/terms/terms.component';
import { WindowComponent } from './components/shared/window/window.component';
import { AiComponent } from './components/ai/ai.component';
import { SvgIconComponent } from './components/shared/svg-icon/svg-icon.component';
import { TableComponent } from './components/ai/table/table.component';
import { AssessmentCellComponent } from './components/shared/assessment-cell/assessment-cell.component';


const routes: Routes = [
  { path: '', component: LandingPageComponent },
  { path: 'signin', component: LoginComponent },
  { path: 'signup', component: RegisterComponent },
  { path: 'welcome', component: WelcomeComponent, canActivate: [AuthenticationGuard] },
  { path: 'resetpassword', component: ResetpasswordComponent },
  { path: 'user/management', component: UserComponent, canActivate: [AuthenticationGuard] },
  { path: 'user', component: UserDetailsComponent, canActivate: [AuthenticationGuard] },
  { path: 'airports/:iata', component: AirportDetailsComponent },
  { path: 'search/:keyword', component: SearchComponent },
  { path: 'about', component: AboutComponent, pathMatch: 'full' },
  { path: 'terms_and_conditions', component: TermsComponent, pathMatch: 'full' },
  { path: '**', component: LandingPageComponent, pathMatch: 'full' }
];

@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    AirportListComponent,
    AirportDetailsComponent,
    MainComponent,
    LoginComponent,
    RegisterComponent,
    UserComponent,
    NavbarComponent,
    ClickElseWhereDirective,
    UserDetailsComponent,
    WelcomeComponent,
    ResetpasswordComponent,
    FeetsToMetersPipe,
    LandingPageComponent,
    FooterComponent,
    CoordinatesNotNullPipe,
    MapComponent,
    AboutComponent,
    TermsComponent,
    WindowComponent,
    AiComponent,
    SvgIconComponent,
    TableComponent,
    AssessmentCellComponent
  ],
  imports: [
    RouterModule.forRoot(routes),
    BrowserModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCrWmV-vwRpzGYmIls9idJ0IXNU45SoZDg'
    }),
    FormsModule,
    NotificationModule,
    BrowserAnimationsModule,
    NgxSliderModule
  ],
  providers: [
    NotificationService,
    AuthenticationGuard,
    AuthenticationService,
    UserService,
    MarkerService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
