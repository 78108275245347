import { Assessment } from "./assessment";

export class ComplexAssessment {
  isDowngraded: boolean;
  downgradedDescription: string;
  downgradedColor: string;
  finalAssessment: Assessment;
  assessments: Assessment[];

  constructor(
    isDowngraded: boolean,
    downgradedDescription: string,
    downgradedColor: string,
    finalAssessment: Assessment,
    assessments: Assessment[],
  ) {
    this.isDowngraded = isDowngraded;
    this.downgradedDescription = downgradedDescription;
    this.downgradedColor = downgradedColor;
    this.finalAssessment = finalAssessment;
    this.assessments = assessments;
  }
}
