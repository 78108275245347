import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, observable } from "rxjs";
import { FormattedPredictionML } from "../common/formattedPredictionML";

@Injectable({
  providedIn: "root",
})
export class AiService {
  // private url = "http://localhost:8080";
  private url = "https://checkairport.net:8443/checkAirport";

  private baseUrlAi = `${this.url}/ai`;

  constructor(private httpClient: HttpClient) {}

  getPredictions(
    iata: string,
    idConfigurator: number,
  ): Observable<FormattedPredictionML[]> {
    const url = `${this.baseUrlAi}/predictions`;

    let params = new HttpParams()
      .set("iata", iata)
      .set("idConfigurator", idConfigurator.toString());

    return this.httpClient.get<FormattedPredictionML[]>(url, {
      params: params,
    });
  }
}
