import { Component, Input, OnInit } from "@angular/core";
import { ComplexAssessment } from "../../../common/complexAssessment";

@Component({
  selector: "app-assessment-cell",
  templateUrl: "./assessment-cell.component.html",
  styleUrls: ["./assessment-cell.component.css"],
})
export class AssessmentCellComponent implements OnInit {
  @Input() assessment: ComplexAssessment;
  @Input() value: number;
  @Input() isFeetsChoosen: boolean;

  constructor() {}

  ngOnInit() {
    // console.log("assessment-ngOnInit", this.assessment);
  }
}
