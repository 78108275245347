import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "svg-icon",
  templateUrl: "./svg-icon.component.html",
  styleUrls: ["./svg-icon.component.css"],
})
export class SvgIconComponent implements OnInit {
  @Input() svgPath: string = ""; // Path to the SVG file in the assets folder
  @Input() color: string = ""; // Desired color (e.g., '#ff0000')
  @Input() hoverColor: string = "";
  @Input() customClass: string = ""; // Optional custom CSS class
  @Input() width: string = "100%"; // Default width
  @Input() height: string = "100%"; // Default height

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
  ) {}

  ngOnInit(): void {
    this.loadSvg();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.color &&
      changes.color.previousValue &&
      changes.color.currentValue != changes.color.previousValue
    ) {
      this.color = changes.color.currentValue;
      this.loadSvg();
    }
  }

  private loadSvg(): void {
    if (this.svgPath) {
      fetch(this.svgPath)
        .then((response) => response.text())
        .then((svgText) => {
          const container =
            this.el.nativeElement.querySelector(".svg-container");
          container.innerHTML = svgText;
          const svgElement = container.querySelector("svg");

          if (svgElement) {
            if (this.color) {
              svgElement.setAttribute("fill", this.color);
              svgElement.setAttribute("stroke", this.color);
            }
            this.renderer.setStyle(svgElement, "width", this.width);
            this.renderer.setStyle(svgElement, "height", this.height);
            if (this.customClass) {
              this.renderer.addClass(svgElement, this.customClass);
            }

            // Add hover effect using Renderer2
            this.renderer.listen(svgElement, "mouseenter", () => {
              if (this.hoverColor) {
                svgElement.setAttribute("fill", this.hoverColor);
                svgElement.setAttribute("stroke", this.hoverColor);
              }
            });

            this.renderer.listen(svgElement, "mouseleave", () => {
              if (this.color) {
                svgElement.setAttribute("fill", this.color);
                svgElement.setAttribute("stroke", this.color);
              }
            });
          }
        })
        .catch((err) => console.error("Error loading SVG:", err));
    }
  }
}
