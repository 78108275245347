import { Component, Input, OnInit } from "@angular/core";
import { Assessment } from "../../../common/assessment";
import { NotificationService } from "../../../services/notification.service";
import { NotificationType } from "../../../enum/notification-type.enum";

@Component({
  selector: "app-ai-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.css"],
})
export class TableComponent implements OnInit {
  @Input() headerContainerColor: String;
  @Input() headerFirst: String;
  @Input() headerSecond: String;
  @Input() headerFirstColor: String;
  @Input() headerSecondColor: String;
  @Input() data: Object[];
  showDetails = false;

  constructor(private notificationService: NotificationService) {}

  ngOnInit() {}

  onClickShowDetails() {
    this.showDetails = !this.showDetails;
  }

  copyToClipboard() {
    let textFinal = this.headerFirst + "" + this.headerSecond + "\n";

    for (let i = 0; i < this.data.length; i++) {
      textFinal += this.data[i]["key"] + ": " + this.data[i]["description"];

      // Add a newline except for the last item
      if (i !== this.data.length - 1) {
        textFinal += "\n";
      }
    }

    navigator.clipboard
      .writeText(textFinal)
      .then(() => {
        this.notificationService.notify(
          NotificationType.SUCCESS,
          "Text successfully copied to clipboard",
        );
      })
      .catch((error) => {
        this.notificationService.notify(
          NotificationType.ERROR,
          `Error copying text: ${error}`,
        );
      });
  }
}
